class ChaloTime {
  constructor() {
    this.time = null;
  }

  // Getter
  getTime() {
    return this.time;
  }

  setTime(time) {
    this.time = time;
  }
}

export default new ChaloTime();
