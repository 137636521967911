import axios from 'axios';
import { authAPI, getDeviceId, getPlainObject } from '../lib/util';

import { API_URLS } from './constants';
import Events from '../lib/events';

/**
 * Server address (for api)
 * @private
 * @constant
 */

// const PROTOCOL = process.env.SSL ? 'https' : 'http';
// const PATH = process.env.API_PATH ? `/${process.env.API_PATH}` : '';
// const API = process.env.API ? `${PROTOCOL}://${process.env.API}${PATH}/` : baseUrl;

// const API_PATH = API || (PATH ? `${PATH}/` : '');

const server = axios.create({
  // baseURL: API_PATH,
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    source: 1,
  },
});

const join = require('url-join');

server.interceptors.request.use((config) => {
  // Concatenate base path if not an absolute URL
  if (config.url.includes('mticketing/v2')) {
    // eslint-disable-next-line no-param-reassign
    config.url = join(process.env.REACT_APP_API_PATH, config.url);
  } else {
    // eslint-disable-next-line no-param-reassign
    config.url = join(process.env.REACT_APP_API_PATH_AUTH, config.url);
  }
  return config;
});
const refreshTokenHandler = async (err, resolve, reject) => {
  const originalReq = err.config;
  // eslint-disable-next-line no-underscore-dangle
  if (err?.response?.status === 401 && err.config && !err.config.__isRetryRequest) {
    // eslint-disable-next-line no-underscore-dangle
    originalReq._retry = true;
    const authData = await authAPI.getAuth();
    const { tokens, userId } = getPlainObject(authData);

    const res = fetch(join(process.env.REACT_APP_API_PATH_AUTH, API_URLS.refreshToken), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      xhrFields: { withCredentials: true },
      cache: 'no-cache',
      mode: 'cors',
      body: JSON.stringify({
        refreshToken: tokens.refreshToken,
        userId,
        deviceId: getDeviceId(),
      }),
    }).then((response) => response.json()).then(async (data) => {
      if (data.accessToken) {
        authAPI.setAuth('tokens', {
          tokens: {
            accessToken: data.accessToken, refreshToken: data.refreshToken,
          },
        });
        originalReq.headers.accessToken = data.accessToken;
        server.defaults.headers.common.accessToken = data.accessToken;
        return axios(originalReq).then((rep) => rep?.data);
      }
      await authAPI.setAuth('phoneNumber', { phoneNumber: null });
      await authAPI.setAuth('userId', { userId: null });
      await authAPI.setAuth('tokens', { tokens: null });
      Events.trigger('logout');
      alert('Session expired.');

      // eslint-disable-next-line no-restricted-globals
      location.replace('/app');

      return true;
    });

    resolve(res);
  }
  return reject(err);
};

server.interceptors.request.use((config) => {
  if (config?.url?.includes(API_URLS.getSeatAvailability)) {
    // eslint-disable-next-line no-param-reassign
    config.headers.post.mode = 2;
  }
  return config;
});

server.interceptors.response.use((response) => response,
  (err) => new Promise((resolve, reject) => refreshTokenHandler(err, resolve, reject)));

export default server;
