/* eslint-disable consistent-return */
import axios from 'axios';
import server from './server';
import formDataParser from '../lib/formDataParser';
import { contentType } from '../lib/constants';
import { authAPI, getDeviceId, getPlainObject } from '../lib/util';

let cancelToken = axios.CancelToken.source();

const multipartFormDataServer = async (options, method) => {
  const authData = await authAPI.getAuth();
  const { tokens, userId } = getPlainObject(authData);
  const { accessToken } = tokens || {};
  const { data, url } = options || {};
  delete data.contentType;
  const formData = formDataParser(data);

  const serverOptions = {
    url,
    method,
    data: formData,
    cancelToken: cancelToken.token,
  };
  if (accessToken) {
    serverOptions.headers = {
      accessToken,
      authType: 'ACCESS_TOKEN',
      userId,
      deviceId: getDeviceId(),
      Accept: 'application/json',
      'x-type': 'pass',
      'Content-Type': 'multipart/form-data',
    };
  }
  return server(serverOptions);
};

const get = async (options) => {
  const authData = await authAPI.getAuth();
  const { tokens, userId } = getPlainObject(authData);
  const { accessToken } = tokens || {};
  const { url, params = {} } = options || {};
  const {
    responseType, skipAccessTokenHeaders, skipXType, extraHeaders,
  } = params;
  if (responseType) {
    delete params.responseType;
  }
  delete params?.extraHeaders;
  delete params?.skipAccessTokenHeaders;
  delete params?.skipXType;
  delete params?.extraHeaders;

  const serverOptions = {
    url,
    method: 'get',
    params,
    responseType,
    cancelToken: cancelToken.token,
  };
  if (accessToken && !skipAccessTokenHeaders) {
    serverOptions.headers = {
      accessToken,
      authType: 'ACCESS_TOKEN',
      userId,
      deviceId: getDeviceId(),
      'Content-Type': 'application/json',
    };
  }
  serverOptions.headers = { ...serverOptions.headers, ...(skipXType ? {} : { 'x-type': 'pass' }) };
  if (extraHeaders) {
    serverOptions.headers = {
      ...serverOptions.headers,
      ...extraHeaders,
    };
  }
  return server(serverOptions);
};

const post = async (options) => {
  const { data, url } = options || {};
  const { addSource = false, extraHeaders = {} } = data;
  if (data?.addSource) {
    delete data.addSource;
  }
  if (extraHeaders) {
    delete data.extraHeaders;
  }
  const authData = await authAPI.getAuth();
  const { tokens, userId } = getPlainObject(authData);
  const { accessToken } = tokens || {};
  if (!data) return;
  if (data.contentType === contentType.MULTIPART) {
    return multipartFormDataServer(options, 'POST');
  }
  const serverOptions = {
    url,
    method: 'POST',
    data,
    cancelToken: cancelToken.token,
  };
  if (accessToken) {
    serverOptions.headers = {
      accessToken,
      authType: 'ACCESS_TOKEN',
      userId,
      deviceId: getDeviceId(),
      'Content-Type': 'application/json',
      ...(addSource ? { source: 1 } : {}),
    };
    if (data?.skipXPass) {
      delete data.skipXPass;
      if (data.data) {
        serverOptions.data = data?.data;
      }
    } else {
      serverOptions.headers['x-type'] = 'pass';
    }
  } else {
    delete data?.skipXPass;
  }
  serverOptions.headers = { ...serverOptions.headers, ...extraHeaders };
  return server(serverOptions);
};

const put = async (options) => {
  const authData = await authAPI.getAuth();
  const { tokens, userId } = getPlainObject(authData);
  const { accessToken } = tokens || {};
  const { data, url } = options || {};
  if (data.contentType === contentType.MULTIPART) {
    return multipartFormDataServer(options, 'PUT');
  }

  const serverOptions = {
    url,
    method: 'PUT',
    data,
    cancelToken: cancelToken.token,
  };
  if (accessToken) {
    serverOptions.headers = {
      accessToken,
      authType: 'ACCESS_TOKEN',
      userId,
      deviceId: getDeviceId(),
      'Content-Type': 'application/json',
      'x-type': 'pass',

    };
  }
  return server(serverOptions);
};

const deleteAPI = async (options) => {
  const { data, url } = options || {};
  const authData = await authAPI.getAuth();
  const { tokens } = getPlainObject(authData);
  const { accessToken } = tokens || {};
  const serverOptions = {
    url,
    method: 'DELETE',
    data,
    cancelToken: cancelToken.token,
  };
  if (accessToken) {
    serverOptions.headers = { accessToken };
  }
  return server(serverOptions);
};

const cancelRequests = () => {
  cancelToken.cancel('REQUEST_CANCELLED_SESSION_TIMEOUT');
  cancelToken = axios.CancelToken.source();
};

export default {
  get,
  post,
  put,
  delete: deleteAPI,
  multipartFormDataServer,
  cancelRequests,
};
