import i18n from 'i18next';

import Backend from 'i18next-http-backend';

import { initReactI18next } from 'react-i18next';

i18n

// load translation using http -> see /public/locales

// learn more: https://github.com/i18next/i18next-http-backend

  .use(Backend)

// detect user language

// learn more: https://github.com/i18next/i18next-browser-languageDetector

//   .use(LanguageDetector)

// pass the i18n instance to the react-i18next components.

// Alternative use the I18nextProvider: https://react.i18next.com/components/i18nextprovider

  .use(initReactI18next)

// init i18next

// for all options read: https://www.i18next.com/overview/configuration-options

  .init({
    fallbackLng: 'en',
    lng: localStorage.getItem('language') || 'en',

    debug: true,

    interpolation: {

      escapeValue: false, // not needed for react as it escapes by default

    },
    backend: {
      loadPath: 'locales/{{lng}}/{{ns}}.json',
    },

    // special options for react-i18next

    // learn more: https://react.i18next.com/components/i18next-instance

    react: {

      wait: true,

    },

  });

export default i18n;
