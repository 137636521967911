import { Map } from 'immutable';

import {
  SET_AUTHENTICATION,
} from '../actions/authentication';

const initialState = Map({ authentication: false });

const actionsMap = {
  [SET_AUTHENTICATION]: (state, { authentication }) => state.setIn(['authentication'], authentication),
};

export default function authenticationReducer(state = initialState, action = {}) {
  const fn = actionsMap[action.type];
  return fn ? fn(state, action) : state;
}
