import React from 'react';
import BoxMUI from '@mui/material/Box';

const Box = ({ children, ...props }) => (
  <BoxMUI {...props}>
    {children}
  </BoxMUI>
);

Box.defaultProps = {
};

export default Box;

