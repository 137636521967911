import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';

import Button from '@mui/material/Button';
import SnackbarMUI from '@mui/material/Snackbar';
import Events from '../../lib/events';

const Snackbar = ({
  spacing,
  message: propMessage,
  buttonText,
  size,
  color,
  anchorOrigin,
  eventId,
  ...restProps
}) => {
  const [message, setMessage] = useState('');
  const [buttonTextNew, setButtonText] = useState('');
  const handleSnackBar = useCallback(() => {
    setMessage('');
    setButtonText('');
  }, []);
  const action = (
    <Button
      color={color}
      size={size}
      onClick={handleSnackBar}
    >
      {buttonTextNew}
    </Button>
  );

  useEffect(() => {
    if (eventId) {
      Events.on('showSnackbar', 'showSnackbar', (errorMessage) => {
        if (errorMessage === 'Network Error' || errorMessage === 'Seems like you are offline. Please check your internet connection and try again') {
          setButtonText(i18next.t('home.okay'));
          setMessage(i18next.t('home.snackBarOffline'));
        } else {
          setButtonText(buttonText);
          setMessage(errorMessage);
        }
      });
      return () => {
        Events.remove('showSnackbar', 'showSnackbar');
      };
    }
    return () => {};
  }, []);

  useEffect(() => {
    if (propMessage === 'Network Error' || propMessage === 'Seems like you are offline. Please check your internet connection and try again') {
      setButtonText(i18next.t('home.okay'));
      setMessage(i18next.t('home.snackBarOffline'));
    } else if (propMessage) {
      setButtonText(buttonText);
      setMessage(propMessage);
    }
  }, [propMessage]);

  return (
    <div className="app-snackbar">
      <SnackbarMUI
        open={!!message && !!buttonTextNew}
        message={message || propMessage}
        onClose={handleSnackBar}
        action={action}
        anchorOrigin={anchorOrigin}
        autoHideDuration={3000}
        {...restProps}
      />
    </div>
  );
};

Snackbar.defaultProps = {
  spacing: 2,
  message: '',
  buttonText: 'CLOSE',
  color: 'secondary',
  size: 'small',
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'right',
  },
};

Snackbar.propTypes = {
  spacing: PropTypes.number,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  anchorOrigin: PropTypes.object,
};
export default Snackbar;

