/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, {
  Suspense,
} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { install } from 'resize-observer';
import Loader from '../components/Loader';
import AirportLiveTrackingScreen from './AirportLiveTrackingScreen';
import HorizontalAirportLiveTrackingScreen from './HorizontalAirportLiveTrackingScreen';

const ChaloApp = React.lazy(() => import('./ChaloApp'));
/* TODO: remove below like once we get actual api */
// import '../api/msw';

// function showPosition(position) {
//   const location = {
//     longitude: position.coords.longitude,
//     latitude: position.coords.latitude,
//   };
//   console.log(location);
// }
// function getLocation() {
//   if (navigator.geolocation) {
//     navigator.geolocation.getCurrentPosition(showPosition);
//   } else {
//     console.log('Geo Location not supported by browser');
//   }
// }
if (!window.ResizeObserver) install();

// let isOutSideCityPopupRendered = false;
const App = () => (
  <BrowserRouter basename="app">

    <Switch>
      <Route path="/airport-static-screens/airport-chalo-bus-time/:terminalId" key="main page">
        <AirportLiveTrackingScreen />
      </Route>
      <Route path="/airport-static-screens/airport-chalo-bus-time-horizontal/:terminalId" key="main page">
        <HorizontalAirportLiveTrackingScreen />
      </Route>
      <Route path="/" key="main page">
        <Suspense fallback={<Loader />}>
          <ChaloApp />
        </Suspense>
      </Route>
    </Switch>
  </BrowserRouter>
);

export default App;
