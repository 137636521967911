import React, { useMemo } from 'react';

import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '../Box';

import './loader.scss';

const Loader = ({ description }) => (
  <div
    style={{ backgroundColor: 'red' }}
  >
    <Modal
      open
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '60%',
          backgroundColor: 'white',
          p: 4,
          boxSizing: 'unset',
        }}
      >
        <div>
          <CircularProgress disableShrink />
        </div>
        <div style={{
          lineHeight: '1.4em',
          display: 'block',
          overflow: 'hidden',
          marginLeft: '20px',
        }}
        >
          {description}
        </div>
      </Box>
    </Modal>
  </div>
);

const languageText = {
  as: 'লোড হৈ আছে...',
  bn: 'লোড হচ্ছে ..',
  en: 'Loading...',
  hi: 'लोड हो रहा है',
  kn: 'ಲೋಡ್ ಆಗುತ್ತಿದೆ ..',
  ml: 'ലോഡ് ചെയ്യുന്നു...',
  mr: 'लोड करीत आहे',
  ta: 'ஏற்றுகிறது ..',
  te: 'లోడింగ్..',
};

const LoadingText = () => {
  const lang = localStorage.getItem('language');
  const loading = useMemo(() => {
    let textLoading = 'Loading...';
    if (lang) {
      textLoading = languageText[(localStorage.getItem('language'))];
      return textLoading;
    }
    return textLoading;
  }, [lang]);
  return loading;
};

Loader.defaultProps = {
  loaderVisible: false,
  description: <LoadingText />,
};

export default Loader;
