import { combineReducers } from 'redux';
import queryApi from './queryApi';
import form from './form';
import formHandler from './formHandler';
import crud from './crud';
import reduxStoreWithId from './reduxStoreWithId';
import authentication from './authentication';

const appReducer = combineReducers({
  queryApi,
  form,
  formHandler,
  crud,
  reduxStoreWithId,
  authentication,
});

export default (state, action) => appReducer(state, action);
