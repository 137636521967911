
import { call, put } from 'redux-saga/effects';
import { batchActions } from 'redux-batched-actions';
import api from '../../api';

const mergeUpdatedData = (state, form, key) => {
  const currentTabData = form.get(form.get('currentTab')).toJS();
  const lastFormSavedData = currentTabData[key];
  return { ...state, ...lastFormSavedData };
};

const logoutHandler = () => ({ type: null });

const createSaga = (
  { setCreateLoading, setCreateData, setCreateError },
) => function* createSagaFunc(
  {
    id, url, params, cacheResponse,
  },
) {
  try {
    yield put(setCreateLoading(id, true));
    const response = yield call(api.post, {
      url,
      data: params.data,
      token: localStorage.getItem('token'),
    });
    if (!cacheResponse) {
      yield put(
        setCreateData(id, response),
      );
    }
  } catch (e) {
    yield put(logoutHandler(e));
    if (!cacheResponse) {
      yield put(setCreateError(id, e));
    }
  } finally {
    yield put(setCreateLoading(id, false));
  }
};

const deleteSaga = (
  { setDeleteLoading, setDeleteData, setDeleteError },
) => function* deleteSagaFunc({
  id,
  url, params,
}) {
  try {
    yield put(setDeleteLoading(id, true));
    const response = yield call(api.delete, {
      url,
      data: params,
      token: localStorage.getItem('token'),
    });
    yield put(
      setDeleteData(id, response),
    );
  } catch (e) {
    yield put(batchActions([
      logoutHandler(e),
      setDeleteError(id, e),
    ]));
  } finally {
    yield put(setDeleteLoading(id, false));
  }
};

const updateSaga = (
  { setUpdateLoading, setUpdateData, setUpdateError },
) => function* updateSagaFunc({
  id, url, params, cacheResponse,
}) {
  try {
    yield put(setUpdateLoading(id, true));
    const response = yield call(api.put, {
      url,
      data: params,
      token: localStorage.getItem('token'),
    });
    if (!cacheResponse) {
      yield put(
        setUpdateData(id, response || { success: true }),
      );
    }
  } catch (e) {
    yield put(logoutHandler(e));
    if (!cacheResponse) {
      yield put(setUpdateError(id, e));
    }
  } finally {
    yield put(setUpdateLoading(id, false));
  }
};

export {
  mergeUpdatedData,
  logoutHandler,
  createSaga,
  deleteSaga,
  updateSaga,
};
