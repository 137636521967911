import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import airportBusImage from '../../Images/bus1.png';
import './airportLiveTrackingScreen.scss';

// http://localhost:3000/app/airport-static-screens/airport-chalo-bus-time/T1

const AirportLiveTrackingScreen = () => {
  const ROUTE_DATA = JSON.parse(process.env.REACT_APP_STATIC_SCREEN_ROUTE_DATA);
  const ROUTE_NAME = JSON.parse(process.env.REACT_APP_STATIC_SCREEN_ROUTE_NAME);
  const BOOKING_COUNTER = JSON.parse(process.env.REACT_APP_BOOKING_COUNTER_PLACE);
  const [currentTime, setCurrentTime] = useState('');
  const [currentEpochTime, setCurrentEpochTime] = useState();
  const [AMPM, setAMPM] = useState();
  const [online, setOnline] = useState(navigator.onLine);
  const isInitialRender = useRef(true);
  const APITimeResponseInterval = useRef();
  const { terminalId } = useParams();

  const [responseData, setResponseData] = useState(null);
  const postData = () => {
    fetch(`${process.env.REACT_APP_API_PATH}tub/v1/trips/mumbai/by_route_stop_pairs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.REACT_APP_AIRPORT_LIVE_API,
      },
      body: JSON.stringify({
        ...ROUTE_DATA?.[terminalId],
        timeWindow: {
          fromTime: Date.now().toString(),
          toTime: (Date.now() + 43200000).toString(),
        },
      }),
    }).then(async (response) => {
      if (response.ok) {
        const json = await response.json();
        setResponseData(json);
      } else {
        console.error('Error:', response?.statusText);
      }
    }).catch((error) => {
      console.error('Error:', error);
    });

    if (APITimeResponseInterval.current) {
      clearInterval(APITimeResponseInterval.current);
    }

    APITimeResponseInterval.current = setInterval(() => {
      fetch(`${process.env.REACT_APP_API_PATH}tub/v1/trips/mumbai/by_route_stop_pairs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: process.env.REACT_APP_AIRPORT_LIVE_API,
        },
        body: JSON.stringify({
          ...ROUTE_DATA?.[terminalId],
          timeWindow: {
            fromTime: Date.now().toString(),
            toTime:
             (Date.now() + 43200000).toString(),
          },
        }),
      }).then(async (response) => {
        if (response.ok) {
          const json = await response.json();
          setResponseData(json);
        } else {
          console.error('Error:', response.statusText);
        }
      }).catch((error) => {
        console.error('Error:', error);
      });
    }, 30000);
  };

  useEffect(() => {
    postData();
  }, []);

  useEffect(() => {
    window.addEventListener('online', () => {
      // Set hasNetwork to online when they change to online.
      setOnline(true);
    });

    window.addEventListener('offline', () => {
      // Set hasNetwork to offline when they change to offline.
      setOnline(false);
    });
    // });
  }, []);

  useEffect(() => {
    // Check online status and reload the page if the user comes back online
    if (!isInitialRender?.current && online) {
      window.location.reload();
    } else {
      isInitialRender.current = false;
    }
  }, [online]);

  useEffect(() => {
    function updateTime() {
      const now = new Date();
      setCurrentEpochTime(now.getTime());
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const formattedTime = `${hours === 12 ? '12' : hours % 12}:${minutes < 10 ? '0' : ''}${minutes}`;
      if (hours >= 12) {
        setAMPM('PM');
      } else {
        setAMPM('AM');
      }
      setCurrentTime(formattedTime);
    }

    updateTime();
    const intervalId = setInterval(updateTime, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, [currentEpochTime]);

  return (
    <div className="airport-live">
      <div className="container">
        <div className="upper-section">
          <div className="heading"> LIVE SCHEDULE </div>
          <div className="sub-heading"> NEXT BUS TO </div>
          <table className="bus-stop-list">
            {responseData?.data?.map((element) => (
              <tr className="bus-stop" key={element?.routeId}>
                <td className="stop stop-name">
                  {ROUTE_NAME.routeName.find((route) => route.routeId
                  === element?.routeId)?.stopName}
                </td>
                {element?.timings?.filter((time) => time > currentEpochTime)?.length
                  ? (
                    <td className="stop stop-time">
                      {(Math.floor((((Math.min(...element.timings?.filter(
                        (time) => time > currentEpochTime,
                      )))
                       - currentEpochTime) / 60000) / 60) > 0)
                      && (
                      <>
                        {Math.floor((((Math.min(...element.timings?.filter(
                          (time) => time > currentEpochTime,
                        )))
                             - currentEpochTime) / 60000) / 60)}
                        {' '}
                        hr
                        {' '}
                      </>
                      )}
                      {Math.floor((((Math.min(...element?.timings?.filter(
                        (time) => time > currentEpochTime,
                      )))
                      - (currentEpochTime)) / 60000) % 60) === 0 && '1'}
                      {Math.floor((((Math.min(...element?.timings?.filter(
                        (time) => time > currentEpochTime,
                      )))
                      - (currentEpochTime)) / 60000) % 60)}
                      {' '}
                      min
                    </td>
                  ) : (<td className="stop stop-time">Not Available</td>)}
              </tr>
            ))}
          </table>
          <div className="current-time">
            CURRENT TIME :
            {' '}
            {currentTime}
            {' '}
            {AMPM}
          </div>

        </div>
        <div className="lower-section">
          <div className="description">
            <span className="book">
              BOOK AT
              {' '}
              {BOOKING_COUNTER?.[terminalId]?.place?.toUpperCase()}
            </span>
            <div className="counter">{BOOKING_COUNTER?.[terminalId]?.counter}</div>
          </div>
        </div>
      </div>
      <div className="bus-section">
        <img src={airportBusImage} alt="airportbus" />
      </div>
    </div>
  );
};

export default AirportLiveTrackingScreen;
